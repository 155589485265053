<template>
  <div id="login">
    <div class="limiter">
      <div
        class="container-login100"
        style="background-image: url('images/attractor-bg.png')"
      >
      <ForgotPassword v-if="ForgotPassword" @changeToLoginView="ForgotPassword = false" @setLoading="setLoading" />
        <div v-if="!ForgotPassword" class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <form class="login100-form validate-form" @submit.prevent>
            <span class="login100-form-title p-b-49"> Login </span>

            <div
              class="wrap-input100 validate-input m-b-23"
              data-validate="Username is reauired"
            >
              <span class="label-input100">Username</span>
              <input
                class="input100"
                name="username"
                id="username"
                placeholder="Username"
                v-model="username"
              />
              <span class="focus-input100" data-symbol="&#xf206;"></span>
            </div>

            <div
              class="wrap-input100 validate-input"
              data-validate="Password is required"
            >
              <span class="label-input100">Password</span>
              <input
                class="input100"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                v-model="password"
              />
              <span class="focus-input100" data-symbol="&#xf190;"></span>
            </div>

            <div class="text-right p-t-8 p-b-31">
              <a href="#" @click="ForgotPassword ? ForgotPassword = false : ForgotPassword = true"> Forgot password? </a>
            </div>

            <div class="container-login100-form-btn">
              <div class="wrap-login100-form-btn">
                <div class="login100-form-bgbtn"></div>
                <button
                  class="login100-form-btn"
                  id="loginSubmit"
                  v-on:click="login"
                >
                  Login
                </button>
              </div>
              <div class="text-center validation-message p-t-8">
                {{ error_message }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="loader-overlay" v-if="loading == true">
      <Loader> </Loader>
    </div>
  </div>
</template>

<script>
import DataService from "@/api/DataService";
import router from "@/router";
import Loader from "@/components/loader.vue";
import Store from "@/store";
import ForgotPassword from "@/components/ForgotPassword.vue";
export default {
  data() {
    return {
      username: null,
      password: null,
      error_message: "",
      loading: false,
      ForgotPassword: false,
    };
  },
  components: {
    Loader,
    ForgotPassword,
  },
  methods: {
    setLoading(val){
      this.loading = val
    },
    async login() {
      this.loading = true;
      var result = await DataService.loginUser(this.username, this.password);
      var data = result.data;
      this.loading = false;
      if (data.message == "login_success") {
        localStorage.setItem("isAuthenticated", true);
        this.error_message = "";
        Store.state.auth.name = data.name;
        localStorage.setItem("name",data.name);
        localStorage.setItem("projects", JSON.stringify(data.projects));
        localStorage.setItem("hashed_username", data.hashed_username);
        router.push({ name: "SupportPackList" });
      } else {

        localStorage.setItem("isAuthenticated", false);
        localStorage.setItem("projectID", null);
        localStorage.setItem("hashed_username", data.hashed_username);
        this.error_message = "Incorrect login credentials. Please try again";
      }
    },
  },
};
</script>

<style>
.validation-message{
  color: red;
}
</style>