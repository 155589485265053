<template>
    <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
        <form class="login100-form validate-form" @submit.prevent>
            <span class="login100-form-title p-b-49"> Reset Password </span>

            <div
            class="wrap-input100 validate-input m-b-23"
            data-validate="Username is reauired"
            >
            <span class="label-input100"></span>
            <input
            class="input100"
            type="email"
            name="email"
            id="email"
            placeholder="Enter your Email"
            v-model="email"
            />
            <span class="focus-input100" data-symbol="&#xf190;"></span>
        </div>


        <div class="container-login100-form-btn">
            <div class="wrap-login100-form-btn">
                <div class="login100-form-bgbtn"></div>
                <button
                class="login100-form-btn"
                id="loginSubmit"
                v-on:click="generateLink"
                >
                Rest Password
            </button>
        </div>
            <a class="login-link" @click="$emit('changeToLoginView')">Or Login</a>
        <div class="text-center validation-message p-t-8">
            {{ error_message }}
        </div>
    </div>
</form>
</div>

</template>

<script>
import DataService from "@/api/DataService.js";


export default {
    data() {
        return {
            email: null,
            loading: false,
            error_message: "",
        };
    },
    components: {
    },
    methods: {
       async generateLink(){
           this.$emit('setLoading', true);
           this.error_message = "";
          let res = await DataService.generateResetLink(this.email);
          console.log(res.data.message);
          this.$emit('setLoading', false);
          if(res.data.message == "email_sent"){
            this.$emit('changeToLoginView')
          }else{
              this.error_message = "Error occurred!"
          }
          
        }

    },
};
</script>

<style>
#login{
    position: relative;
}
.login-link{
    display: inline-block;
    text-decoration: none;
    color: #000;
    margin-top: 15px;
    font-size: 16px;
}
.login-link:hover{
    color: #c73030;
}
</style>